<template>
  <div>
    <main v-if="page" :class="page.padding && [config.padding[page.padding]]">
      <cms-layout-article v-if="page.templateName === 'article'" :page>
        <cms-section v-for="section in page?.sections" :key="section.name" :section page-context />
      </cms-layout-article>

      <cms-section v-for="section in page?.sections" v-else :key="section.name" :section page-context />
    </main>
  </div>
</template>

<script lang="ts" setup>
definePageMeta({
  validate({ path }) {
    return !/\.\w{2,5}$/.test(path)
  }
})

const route = useRoute()
const config = useAppConfig().pages.cms
const slug = useRouteParams<string>('slug')
const { monetateConfig } = useFeatureFlags()
const { $gtm, $sendExtraMonetateEvents } = useNuxtApp()
const { getPage, getSeoMetadata } = useCms()
const transparent = useState<boolean>('headerTransparent')

const page = await getPage(slug.value)

if (page) {
  const { metadata, link } = getSeoMetadata(page)
  useSeoMeta(metadata)
  useHead(() => ({ link }))
}

// defines markup via unhead-schema for search functionality on homepage
// https://unhead.unjs.io/schema-org/getting-started/how-it-works
useSchemaOrg([
  defineWebSite({
    potentialAction: [
      defineSearchAction({
        target: '/search?q={search_term_string}'
      })
    ]
  })
])

const configTransparentHeader = () => {
  // Temp workaround for showing a transparent header on the main page until CMS will send us this data in the request
  if (config.headerTransparent) transparent.value = !slug.value?.[0]

  if (!transparent.value)
    transparent.value = !!(page?.name && page?.name.indexOf('[elevate]') >= 0)
}

configTransparentHeader()

onMounted(async () => {
  // Temp workaround for showing a transparent header on the main page until CMS will send us this data in the request
  configTransparentHeader()

  $gtm.push('page.onLoadPageData', route, {
    pageTitle: await getPageTitle(),
    pageCategory: slug.value ? 'Content' : 'Home',
    pageName: slug.value ? page?.name : 'Home'
  })

  const mainSlug = slug.value?.[0]

  if (monetateConfig?.extraMonetateEventsPages?.includes(mainSlug) || !mainSlug)
    $sendExtraMonetateEvents()

  $gtm.push('user.onLoadUserData', await getUserEventData())
  $gtm.push('page.onRouterChange', route)
})

onBeforeUnmount(() => {
  transparent.value = false
})
</script>
